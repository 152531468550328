<template>
  <div v-if="items && items.length > 0">
    <PageBuilderElement v-for="(component, index) in items"
                        :key="index"
                        :element="component"
    />

    <div v-if="showFooterBorder"
         class="w-full h-px bg-gray-300"
    />
  </div>

  <div v-else-if="content">
    <SectionPrivacy :content="content" />
  </div>
</template>

<script setup lang="ts">
import { createError, useAsyncData, queryContent, useHead } from '#imports';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import routesList from '../content/routes.json';

type PageType = {
  url: string;
  id: string;
  collection: string;
};

const route = useRoute();

let uri = route && route.path ? route.path : '/';

// if length more than 1, strip ending slash
if (uri.length > 1 && uri.endsWith('/')) {
  uri = uri.slice(0, -1);
}

const page = routesList.find(item => item.url === uri) as PageType;

if (!page) {
  throw createError({
    statusCode: 404,
    statusMessage: 'Page Not Found',
    fatal: true
  });
}

// Default entry id is home
const entry_id = page.id || 'home';

// Privacy pages are those that are loaded from markdown files
const { data } = page.collection === 'privacy' ?
  await useAsyncData(uri, () =>
    queryContent('privacy')
      .where({
        slug: `/${route.params.slug[0]}/`
      })
      .findOne()
  ) :
  await useAsyncData(uri, () => queryContent('pages', entry_id).findOne());

useHead({
  title: data.value?.meta?.title || data.value?.title,
  link: [
    {
      rel: 'canonical',
      href: 'https://outfit7.com' + route.path
    }
  ],
  meta: [
    { hid: 'description', name: 'description', content: data.value?.meta?.description },
    { hid: 'og:title', property: 'og:title', content: data.value?.social?.title },
    // { hid: 'og:url', property: 'og:url', content: this.pageUrl },
    { hid: 'og:description', property: 'og:description', content: data.value?.social?.description },
    { hid: 'og:image', property: 'og:image', content: data.value?.social?.image },
    { hid: 'twitter:title', name: 'twitter:title', content: data.value?.social?.title },
    // { hid: 'twitter:url', name: 'twitter:url', content: this.pageUrl },
    { hid: 'twitter:description', name: 'twitter:description', content: data.value?.meta?.description },
    { hid: 'twitter:image', name: 'twitter:image', content: data.value?.social?.image }
  ]
});

const items = computed(() => {
  if (data && data && data.value && data.value.items && data.value.items.length > 0) {
    return data.value.items;
  }

  return false;
});

const content = computed(() => {
  if (data && data && data.value && page.collection === 'privacy') {
    return data.value;
  }

  return false;
});

// Hide footer border if the last component of the page is in the footerBorderComponents array
const showFooterBorder = computed(() => {
  const footerBorderComponents = ['slider_bottom_heading', 'newsletter'];

  if (items.value.length > 0) {
    const lastEl = items.value[items.value.length - 1];
    if (lastEl.component_type === 'heading' && (lastEl.type === 'secondary' || lastEl.backgroundType === 'gradient')) return false;
    return !footerBorderComponents.includes(lastEl.component_type);
  }

  return false;
});
</script>